import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { getResourcesUrl, getTaxonomyUrl } from '../utils/urls';
import PageHeader from '../components/molecules/page-header';
import Intro from '../components/molecules/intro';
import Archive from '../components/organisms/archive';
import FormRenderer from '../components/organisms/form-renderer';

class Library extends React.Component {
  state = {
    filterOpen: false,
  };

  static getFilteredUrl(rootUrl, taxonomy, category) {
    if (category) {
      return getTaxonomyUrl(rootUrl, taxonomy, category);
    }

    return rootUrl;
  }

  toggleFilterClick = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    });
  };

  render() {
    const {
      data,
      pageContext,
      selectFilterText,
      openFilterText,
      closeFilterText,
      clearFilterText,
      rootFilterText,
    } = this.props;
    const { filtered, page: currentPage, pages: pageCount, nodes } = pageContext;
    const { filterOpen } = this.state;

    const postCategories = data.postCategories && data.postCategories.edges;

    const categoryObj =
      data.currentCategory && data.currentCategory.edges.length > 0 && data.currentCategory.edges[0].node;

    const category = categoryObj && categoryObj.slug;

    const rootUrl = getResourcesUrl();
    const taxonomy = 'category';
    const filteredUrl = Library.getFilteredUrl(rootUrl, taxonomy, category);

    const pagination = {
      filtered,
      current: currentPage,
      pageCount,
    };

    const ogImage = data.page.yoast.ogImage && data.page.yoast.ogImage.localFile;
    const twitterImage = data.page.yoast.twitterImage && data.page.yoast.twitterImage.localFile;

    return (
      <Layout>
        <Seo
          fallbackTitle={data.page.title}
          title={data.page.yoast.title}
          description={data.page.yoast.metadesc}
          ogImage={ogImage}
          twitterImage={twitterImage}
        />
        <PageHeader title={data.page.title} />
        <Intro title={data.page.acf.intro} />
        <Archive
          items={nodes}
          filterItems={postCategories}
          rootUrl={rootUrl}
          filteredUrl={filteredUrl}
          activeFilter={category}
          filterTaxonomy={taxonomy}
          selectFilterText={selectFilterText}
          openFilterText={openFilterText}
          closeFilterText={closeFilterText}
          clearFilterText={clearFilterText}
          rootFilterText={rootFilterText}
          pagination={pagination}
          filterOpen={filterOpen}
          toggleFilterClick={this.toggleFilterClick}
          renderActiveFilter={filterName => {
            return `Posts ${filterName}`;
          }}
          getCardProps={itemData => {
            const { node } = itemData;

            const {
              title,
              slug,
              date,
              categories,
              featured_media: featuredMedia,
              excerpt,
              content,
              wordpress_id: id,
            } = node;

            const category = categories && categories[0];
            const { name: categoryName, slug: categorySlug } = category;
            const imageObj = featuredMedia || undefined;

            return {
              id,
              title,
              slug,
              date,
              categoryName,
              categorySlug,
              imageObj,
              excerpt,
              content,
            };
          }}
        />
        <FormRenderer form={{ wordpress_id: data.form.wordpress_id, ...data.form.acf }} />
      </Layout>
    );
  }
}

Library.defaultProps = {
  selectFilterText: 'Browse by category',
  openFilterText: 'Open filter',
  closeFilterText: 'Close filter',
  clearFilterText: 'Back to all posts',
  rootFilterText: 'All',
};

export const query = graphql`
  query($id: Int!, $category: Int) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      yoast {
        title
        metadesc
        ogImage: opengraph_image {
          localFile {
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        twitterImage: twitter_image {
          localFile {
            childImageSharp {
              resize(width: 1000, height: 1000) {
                src
              }
            }
          }
        }
      }
      title
      acf {
        intro
      }
    }
    postCategories: allWordpressWpLibraryCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    currentCategory: allWordpressWpLibraryCategory(filter: { wordpress_id: { eq: $category } }) {
      edges {
        node {
          slug
        }
      }
    }
    form: wordpressWpForm(wordpress_id: { eq: 230 }) {
      wordpress_id
      acf {
        title
        subtitle
        introduction
        cta_button_text
        note
        mailchimp_url
        mailchimp_user_id
        mailchimp_list_id
        mailerlite_user_id
        mailerlite_form_id
        success_message
        generic_error_message
        already_subscribed_error_message
        background_image {
          localFile {
            childImageSharp {
              sizes(maxWidth: 640) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`;

export default Library;
