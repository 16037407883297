import React, { useRef } from 'react';
import * as Yup from 'yup';
import { ErrorMessage } from 'formik';

import { MESSAGE_REQUIRED_FIELD, MESSAGE_INVALID_EMAIL } from '../../utils/constants';
import mailerliteSubmit from '../../utils/mailerlite-submit';
import { showFormError } from '../../utils/form';

import FormLayout from '../organisms/form-layout';
import Form from '../organisms/form';
import TextInput from '../atoms/text-input';
import withFormField from '../atoms/form-field';
import Button from '../atoms/button';

const FancyTextInput = withFormField(TextInput);

const validationSchema = Yup.object().shape({
  EMAIL: Yup.string()
    .email(MESSAGE_INVALID_EMAIL)
    .required(MESSAGE_REQUIRED_FIELD),
});

const initialValues = {
  EMAIL: '',
};

const NewsletterSignupForm = ({
  title,
  subtitle,
  introduction,
  cta_button_text: ctaButtonText,
  note,
  background_image: backgroundImage,
  success_message: successMessage,
  generic_error_message: errorMessage,
  already_subscribed_error_message: alreadySubscribedErrorMessage,
  Information: information,
  id,
  mailerlite_user_id: mailerliteUserId,
  mailerlite_form_id: mailerliteFormId,
}) => {
  const action = `//assets.mailerlite.com/jsonp/${mailerliteUserId}/forms/${mailerliteFormId}/subscribe`;
  const ref = useRef();

  return (
    <FormLayout
      ref={ref}
      backgroundImage={backgroundImage}
      title={title}
      subtitle={subtitle}
      introduction={introduction}
      id={id}
      information={information}
    >
      <Form
        container={ref}
        method="post"
        action={action}
        className="form-newsletter-signup"
        name="newsletter-signup"
        fieldWrapper={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        successMessage={successMessage}
        errorMessage={errorMessage}
        handleSubmit={values => {
          return mailerliteSubmit(mailerliteUserId, mailerliteFormId, values);
        }}
        parseError={error => {
          const message = error instanceof Error && typeof error.message === 'string' ? error.message : errorMessage;

          if (message.includes('already subscribed to list')) {
            return alreadySubscribedErrorMessage;
          }

          return errorMessage;
        }}
        botFieldName="b_5eae0b68be96658ab68947d28_5cdff76c1a"
        render={(errors, touched) => (
          <div className="form-newsletter-signup__inner">
            <FancyTextInput
              errored={showFormError('EMAIL', errors, touched)}
              formName="newsletter-signup"
              id="email"
              label="Email"
              className="form-newsletter-signup__email"
              wrapperClass="form-newsletter-signup__field-wrapper"
              type="email"
              name="EMAIL"
              required
              placeholder="Enter your email address here"
              error={(name, cssClass) => <ErrorMessage className={cssClass} name={name} component="div" />}
              hideErrorMessage
            />
            <p className="form-newsletter-signup__note">{note}</p>
            <Button
              className="form-newsletter-signup__cta"
              type="submit"
              disabled={Object.keys(errors).length > 0 && Object.keys(touched).length > 0}
            >
              {ctaButtonText}
            </Button>
          </div>
        )}
      />
    </FormLayout>
  );
};

NewsletterSignupForm.defaultProps = {
  id: null,
  success_message: 'Thanks for signing up to the mailing list. Check your inbox to confirm your subscription. Thanks!',
  generic_error_message: 'There has been a problem signing up to the mailing list. Please try again later',
  already_subscribed_error_message:
    'There has been a problem signing up to the mailing list. You are already subscribed',
};

export default NewsletterSignupForm;
