import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { getBlogUrl, getTaxonomyUrl } from '../utils/urls';
import Layout from '../components/layout';
import Seo from '../components/seo';
import PageHeader from '../components/molecules/page-header';
import FeaturedPost from '../components/molecules/featured-post';
import Archive from '../components/organisms/archive';
import FormRenderer from '../components/organisms/form-renderer';

class Blog extends Component {
  state = {
    filterOpen: false,
  };

  static getFilteredUrl(rootUrl, taxonomy, category) {
    if (category) {
      return getTaxonomyUrl(rootUrl, taxonomy, category);
    }

    return rootUrl;
  }

  toggleFilterClick = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    });
  };

  render() {
    const {
      data,
      pageContext,
      featuredSubtitle,
      selectFilterText,
      openFilterText,
      closeFilterText,
      clearFilterText,
      rootFilterText,
    } = this.props;

    const { filtered, page: currentPage, pages: pageCount, nodes } = pageContext;

    const { filterOpen } = this.state;

    const rootUrl = getBlogUrl();
    const taxonomy = 'category';

    const featuredPost = data.featuredPost && data.featuredPost.edges.length > 0 && data.featuredPost.edges[0].node;
    const categoryObj =
      data.currentCategory && data.currentCategory.edges.length > 0 && data.currentCategory.edges[0].node;

    const category = categoryObj && categoryObj.slug;

    const featuredPostMedia = featuredPost && featuredPost.featured_media;
    const postCategories = data.postCategories && data.postCategories.edges;

    const filteredUrl = Blog.getFilteredUrl(rootUrl, taxonomy, category);

    const pagination = {
      filtered,
      current: currentPage,
      pageCount,
    };

    const ogImage = data.page.yoast.ogImage && data.page.yoast.ogImage.localFile;
    const twitterImage = data.page.yoast.twitterImage && data.page.yoast.twitterImage.localFile;

    return (
      <Layout>
        <Seo
          fallbackTitle={data.page.title}
          title={data.page.yoast.title}
          description={data.page.yoast.metadesc}
          ogImage={ogImage}
          twitterImage={twitterImage}
        />
        <PageHeader title={data.page.title} standalone={filtered === true} />
        {featuredPost && !filtered && (
          <FeaturedPost
            subTitle={featuredSubtitle}
            title={featuredPost.title}
            slug={featuredPost.slug}
            date={featuredPost.date}
            image={featuredPostMedia}
            rootUrl={rootUrl}
            taxonomy={taxonomy}
            categoryName={featuredPost.categories[0].name}
            categorySlug={featuredPost.categories[0].slug}
            content={featuredPost.content}
            excerpt={featuredPost.excerpt}
          />
        )}
        <Archive
          items={nodes}
          filterItems={postCategories}
          rootUrl={rootUrl}
          filteredUrl={filteredUrl}
          activeFilter={category}
          filterTaxonomy={taxonomy}
          selectFilterText={selectFilterText}
          openFilterText={openFilterText}
          closeFilterText={closeFilterText}
          clearFilterText={clearFilterText}
          rootFilterText={rootFilterText}
          pagination={pagination}
          filterOpen={filterOpen}
          toggleFilterClick={this.toggleFilterClick}
          renderActiveFilter={filterName => {
            return `Posts about ${filterName}`;
          }}
          getCardProps={itemData => {
            const { node } = itemData;

            const {
              title,
              slug,
              date,
              categories,
              featured_media: featuredMedia,
              excerpt,
              content,
              wordpress_id: id,
            } = node;

            const category = categories && categories[0];
            const { name: categoryName, slug: categorySlug } = category;
            const imageObj = featuredMedia || undefined;

            return {
              id,
              title,
              slug,
              date,
              categoryName,
              categorySlug,
              imageObj,
              excerpt,
              content,
            };
          }}
        />
        <FormRenderer form={{ wordpress_id: data.form.wordpress_id, ...data.form.acf }} />
      </Layout>
    );
  }
}

Blog.defaultProps = {
  featuredSubtitle: 'Latest from the blog',
  selectFilterText: 'Browse by category',
  openFilterText: 'Open filter',
  closeFilterText: 'Close filter',
  clearFilterText: 'Back to all posts',
  rootFilterText: 'All',
};

export const query = graphql`
  query($id: Int!, $category: Int) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      yoast {
        title
        metadesc
        ogImage: opengraph_image {
          localFile {
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        twitterImage: twitter_image {
          localFile {
            childImageSharp {
              resize(width: 1000, height: 1000) {
                src
              }
            }
          }
        }
      }
      title
    }
    featuredPost: allWordpressPost(limit: 1) {
      edges {
        node {
          title
          content
          excerpt
          slug
          date(formatString: "Do MMMM YYYY")
          categories {
            name
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                sizes(maxWidth: 780) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
    postCategories: allWordpressCategory(filter: { wordpress_id: { ne: 1 } }) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    currentCategory: allWordpressCategory(filter: { wordpress_id: { eq: $category } }) {
      edges {
        node {
          slug
        }
      }
    }
    form: wordpressWpForm(wordpress_id: { eq: 230 }) {
      wordpress_id
      acf {
        title
        subtitle
        introduction
        cta_button_text
        note
        mailchimp_url
        mailchimp_user_id
        mailchimp_list_id
        mailerlite_user_id
        mailerlite_form_id
        success_message
        generic_error_message
        already_subscribed_error_message
        background_image {
          localFile {
            childImageSharp {
              sizes(maxWidth: 640) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`;

export default Blog;
