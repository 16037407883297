import jsonp from 'jsonp';
import { encode } from './form';

export default function mailerliteSubmit(userId, listId, values) {
  const newValues = {
    'fields[email]': values.EMAIL,
    'ml-submit': '1',
    anticsrf: 'true',
    ajax: '1',
  };

  const url = `//assets.mailerlite.com/jsonp/${userId}/forms/${listId}/subscribe?${encode(newValues)}`;

  return new Promise((resolve, reject) => {
    jsonp(
      url,
      {
        timeout: 5000,
      },
      (err, data) => {
        if (err || !data.success) {
          reject(new Error('Unknown'));
        }

        resolve(true);
      }
    );
  });
}
