import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import parseFlexibleContent from '../utils/parse-flexible-content';
import renderFlexibleContent from '../utils/render-flexible-content';
import { SINGLE } from '../utils/constants';
import PageHeader from '../components/molecules/page-header';
import Intro from '../components/molecules/intro';
import CourseList from '../components/molecules/course-list';
import FormRenderer from '../components/organisms/form-renderer';

const modules = {
  Intro,
  Products: CourseList,
  Form: FormRenderer,
};

const Webinars = ({ data }) => {
  const flexibleContent = parseFlexibleContent(data.page.acf.flexible_content_page);

  const injectData = {
    Products: {
      prefix: '/webinars',
      items: data.webinars.edges,
    },
  };

  const embeds = [
    {
      key: 'form',
      type: SINGLE,
    },
  ];

  const ogImage = data.page.yoast.ogImage && data.page.yoast.ogImage.localFile;
  const twitterImage = data.page.yoast.twitterImage && data.page.yoast.twitterImage.localFile;

  return (
    <Layout>
      <Seo
        fallbackTitle={data.page.title}
        title={data.page.yoast.title}
        description={data.page.yoast.metadesc}
        ogImage={ogImage}
        twitterImage={twitterImage}
      />
      <PageHeader title={data.page.title} />
      {renderFlexibleContent(flexibleContent, modules, injectData, embeds)}
    </Layout>
  );
};

export const query = graphql`
  query($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      yoast {
        title
        metadesc
      }
      title
      acf {
        flexible_content_page {
          module: __typename
          ... on WordPressAcf_intro {
            title
            body
          }
          ... on WordPressAcf_form {
            form {
              wordpress_id
              acf {
                title
                subtitle
                introduction
                cta_button_text
                note
                mailchimp_url
                mailchimp_user_id
                mailchimp_list_id
                mailerlite_user_id
                mailerlite_form_id
                success_message
                generic_error_message
                already_subscribed_error_message
                background_image {
                  localFile {
                    childImageSharp {
                      sizes(maxWidth: 640) {
                        ...GatsbyImageSharpSizes
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    webinars: allWordpressWpWebinar(sort: { fields: [menu_order], order: ASC }) {
      edges {
        node {
          id
          title
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                sizes(maxWidth: 640) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          acf {
            tagline
          }
        }
      }
    }
  }
`;

export default Webinars;
